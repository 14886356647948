/* Provide sufficient contrast against white background */

figure#main-img {
    width: 100%;
    height: 80vh;
    display: block;
    overflow: hidden;
    text-align: center;
    padding-top: 20px;
}

    figure#main-img img {
        height: 100vh;
        object-fit: contain;

    }

